import React, { useEffect, useState, useContext } from "react";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
import { Accordion, Card, Container, Row, Col, Navbar, Nav, Image } from 'react-bootstrap';
import { Button as ReactStrapButton } from 'reactstrap';
import { GlobalContext } from "../../../App/App";
import PDFViewer from "../../../Form/Fields/PdfViewer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileImage } from '@fortawesome/free-solid-svg-icons';
// config file


const Imaging = (props) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [activeKey, setActiveKey] = useState("0");
  const context = useContext(GlobalContext);
  const [folders, setFolders] = useState([
    { title: "Medical History", details: " ", photos: [] },
    { title: "Patient Attachments", details: " ", photos: [] },
    { title: "Forms", details: " ", photos: [] },
    { title: "Treatment Plans", details: " ", photos: [] },
    { title: "Tooth Charts", details: " ", photos: [] },
    { title: "Referrals", details: " ", photos: [] }
  ]);
  
  useEffect(() => {
    if (context?.patientData === null || (typeof context?.patientData === 'string' && context.patientData.trim() === "")) {
      showToasterMessage("Please select a patient", "warning");
    }
  }, [context]);  
  useEffect(() => {
    const fetchPatientImagingData = async () => {
      const filterCriteria = {
        limit: 50, page: 1,
        criteria: [{ key: 'patientId', value: context?.patientData?._id, type: 'eq' }],
        direction: 'desc', softfield: 'created'
      };

      const response = await fetchMethodRequest('GET', `${apiCalls.imageMaintaining}?filter=${JSON.stringify(filterCriteria)}`);
      if (response && response.imagemaintainings) {
        categorizeData(response.imagemaintainings);
      }
    };

    // Reset selected item and folders before fetching new data
    setSelectedItem(null);
    setFolders(folders.map(folder => ({ ...folder, photos: [] })));

    fetchPatientImagingData();
  }, [context.patientData]);

  const getFileIcon = (fileType) => {
    switch (fileType) {
      case 'pdf':
        return <FontAwesomeIcon icon={faFilePdf} />;
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
        return <FontAwesomeIcon icon={faFileImage} />;
      default:
        return null; // No icon for unsupported file types
    }
  };

  const categorizeData = (data) => {
    const updatedFolders = folders.map(folder => ({
      ...folder,
      photos: []
    }));

    data.forEach(item => {
      // Combine attachments and file uploads into a single array, filtering out undefined arrays
      const allFiles = [...(item.attachment || []), ...(item.fileUploads || []), ...(item.formUploads || [])];

      allFiles.forEach(file => {
        let filePath = file.filePath || file.SignedfilePath;
        let fileName = file.fileName || file.Signfilename;

        if (!filePath || !fileName) {
          return;
        }

        const fileType = filePath.split('.').pop().toLowerCase();
        let urlPath;

        if (fileType === 'pdf') {
          if (file.type === 'Treatment Plan sign' || file.type === 'Referrals' || file.type === 'Treatment Plan'|| file.type === 'form upload') {
            urlPath = `${config.previousImageUrl}${filePath}`;
          } else {
            urlPath = `${config.imgUrl}${filePath}`;
          }
        } else if (['jpg', 'jpeg', 'png', 'gif'].includes(fileType)) {
          urlPath = `${config.imgUrl}${filePath}`;
        } else {
          return;
        }

        // Determine which folder to add the attachment to
        let folderToUpdate = null;
        if (file.type === 'Treatment Plan sign' || file.type === 'Treatment Plan') {
          folderToUpdate = 'Treatment Plans';
        } else if (file.type === 'Patient Image' || file.type === 'Before Treatment' || file.type === 'After Treatment' || file.type === 'fileUploads') {
          folderToUpdate = 'Patient Attachments';
        } else if (file.type === 'Referrals') {
          folderToUpdate = 'Referrals';
        }  else if (file.type === 'form upload') {
          folderToUpdate = 'Forms';
        }

        // Push the attachment to the correct folder's photos array
        if (folderToUpdate) {
          updatedFolders.forEach(folder => {
            if (folder.title === folderToUpdate) {
              folder.photos.push({
                name: fileName,
                url: urlPath,
                type: fileType
              });
            }
          });
        }
      });
    });

    setFolders(updatedFolders);
  }; 

  const handleSelect = (item, event) => {
    event.stopPropagation();
    setSelectedItem(item);
  };


  const renderContent = (item, context = 'folder') => {
    if (!item || !item.url) return <h5>Select an item to view its details.</h5>;
    const fileType = item.url.split('.').pop().toLowerCase();
    if (context === 'detailView') {
      let imageSize = { width: '300px', height: '300px' };
      switch (fileType) {
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'gif':
          return (
            <Image
              src={item.url}
              style={{ width: imageSize.width, height: imageSize.height, maxWidth: '100%', maxHeight: '100%' }}
              fluid
            />
          );
        default:
          return null;
      }
    }
  };



  const renderFileName = (item) => {
    if (!item || !item.url) return null;
    const fileType = item.url.split('.').pop().toLowerCase();
    const isSelected = selectedItem && item.url === selectedItem.url;
    if (['png', 'jpg', 'jpeg', 'gif', 'pdf'].includes(fileType)) {
      return (
        <p style={{
          cursor: 'pointer',
          textDecoration: 'underline',
          margin: '5px 0',
          textTransform: 'capitalize',
          backgroundColor: isSelected ? '#f0f0f0' : 'transparent',
          padding: '5px',
          borderRadius: '5px',
          border: isSelected ? '1px solid #007bff' : 'none',
          fontWeight: 'bold',
          color:'blue',
          fontSize:'15px'
        }}>
          {getFileIcon(fileType)} {item.name}
        </p>
      );
    }
  };

  const renderPdfViewer = (item) => {
    if (item && item.url && item.url.endsWith('.pdf')) {
      return (
        <div style={{ width: '100%', height: '100vh', border: 'none' }}>
          <PDFViewer
            url={item.url}
            canvasStyle={{ width: '100%', height: '100%' }}
          />
        </div>
      );
    }
    return <p>PDF not available</p>;
  };

  return (
    <Container fluid>
    {context?.patientData ? (
      <Row>
        <Col md={4} lg={3} className="mb-4">
          <div style={{ maxHeight: '100vh', overflowY: 'auto' }}>
            <Accordion activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
              {folders.map((folder, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                  <Accordion.Header>{folder.title}</Accordion.Header>
                  <Accordion.Body>
                    {folder.details}
                    {folder.photos.map((photo, photoIndex) => (
                      <div key={`${photo.name}-${photoIndex}`} onClick={(e) => handleSelect(photo, e)}>
                        {renderContent(photo, 'folder')}
                        {renderFileName(photo)}
                      </div>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </Col>
        <Col md={8} lg={9}>
          <Card>
            <Card.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              {selectedItem && selectedItem.url.endsWith('.pdf')
                ? renderPdfViewer(selectedItem)
                : renderContent(selectedItem, 'detailView')}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    ) : (
      <h4>Please select a Patient on top</h4>
    )}
  </Container>
  );
}
export default Imaging;
