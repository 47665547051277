import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef ,useContext} from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Calendar, momentLocalizer, Views, Navigate } from 'react-big-calendar';
import { Calendar as PrimeCalendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
// import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
// import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../../scss/component/appointmentcln.scss';
import '../../../scss/component/draganddrop.scss';
import formatDate from '../../UI/FormatDate/formatDate';
import { Dialog } from 'primereact/dialog';
import fetchMethodRequest from '../../../config/service';
import { InputText } from 'primereact/inputtext';
import apiCalls from '../../../config/apiCalls';
import config from '../../../config/config';
import DoMultiSelectField from './DoMultiSelectField';
import DoAutoCompleteField from './DoAutoCompleteField';
import { ProgressSpinner } from 'primereact/progressspinner';
import Loader from '../../App/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faBookmark, faBan, faCalendar,faTrash,faCaretDown,faAngleLeft, faAngleRight ,faCut,faCopy,faEllipsisV,faCalendarCheck,faPen, faListOl} from '@fortawesome/free-solid-svg-icons';
import { Tooltip, Overlay } from 'react-bootstrap';
import { ColorPicker } from 'primereact/colorpicker';
import { ListBox } from 'primereact/listbox';
import { SketchPicker } from 'react-color';
import ColorPickerWithTooltip from './ColorPickerWithTooltip';
import { Toast } from 'primereact/toast';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import TabbedInterface from '../../Cruds/CommonModals/AddAppointment';
import CreateSlotModal from '../../Cruds/CommonModals/AddSlot';
import UpdateAppointmentModal from '../../Cruds/CommonModals/UpdateAppointment';
import { TabView, TabPanel } from 'primereact/tabview';
import AppointmentCards from './AppointmentCards';
import clsx from 'clsx';
import WeekendConfirmModal from '../../Cruds/CommonModals/WeekendConfirmModal';
import { GlobalContext } from '../../App/App';
// import DndCalender from './DndCalender';
import AddAppointmentsData from '../../Cruds/CommonModals/AddAppointmentsData';
import { Link } from 'react-router-dom';

/**
 * A React component that renders a draggable and droppable big calendar for managing events and appointments.
 * It includes functionalities to create, update, and display slots and appointments.
 *
 * @param {Object} props - The props passed to the component.
 * @param {Ref} ref - A ref object for parent components to interact with this component.
 */
const DoBigCalendar = forwardRef((props, ref) => {

  const currentTime = new Date();
  currentTime.setHours(currentTime.getHours() - 1, 0, 0, 0);
  const [events, setEvents] = useState([]);
  const [backgroundEvents, setBackgroundEvents] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [eventName, setEventName] = useState('');
  const [eventDate, setEventDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [changedEvents, setChangedEvents] = useState([]);
  const [dialogTitle, setDialogTitle] = useState('');
  const [eventNameLabel, setEventNameLabel] = useState('');
  const [currentAction, setCurrentAction] = useState(null);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [slotsEvents, setSlotsEvents] = useState([]);
  const [appointmentsEvents, setAppointmentsEvents] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [allAppointments, setAllAppointments] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [allSchedules,setAllSchedules] = useState([]);
  const [cancelAppointmentId, setCancelAppointmentId] = useState([]);
  const [isMatchingSlotFound, setIsMatchingSlotFound] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(null);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [eventToCancel, setEventToCancel] = useState(null);
  const [draggedEvent, setDraggedEvent] = useState(null);
  const [isDragConfirmDialogVisible, setIsDragConfirmDialogVisible] = useState(false);
  const [slots, setSlots] = useState([]);
  const [resizeEvent, setResizeEvent] = useState(null);
  const [isResizeConfirmDialogVisible, setIsResizeConfirmDialogVisible] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentDateTime, setCurrentDateTime] = useState();
  const [slotModelTrue, setSlotModelTrue] = useState(null);
  const [handlers, setHandlers] = useState([]);
  const [selectedHospitalId, setSelectedHospitalId] = useState(null);
  const [selectedHandler, setSelectedHandler] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);
  const [date, setDate] = useState(new Date());
  const toast = useRef(null);
  const [scrollTime, setScrollTime] = useState(currentTime);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [activeIndex, setActiveIndex] = useState(0);
  const [pasteEventOperative, setPasteEventOperative] = useState(null);
  const [selectedPasteEvent, setSelectedPasteEvent] = useState(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [changedDate , setChangedDate] = useState(new Date());
  const [slotInterval, setSlotInterval] = useState(0);
  const [calendarFromTime , setCalendarFromtime] = useState(new Date());
  const [calendarToTime , setCalendarToTime] = useState(new Date());
  const [timesAreSet, setTimesAreSet] = useState(false);
  const [viewType, setViewType] = useState('today');
  const [showWeekendConfirmModal, setShowWeekendConfirmModal] = useState(false);
  const [appointmentWeekendInfo, setAppointmentWeekendInfo] = useState({});
  const [isWeekendConfirm, setIsWeekendConfirm] = useState(false);
  const [copiedDate, setCopiedDate] = useState();
  const [copiedType, setCopiedType] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [clinicData, setClinicData] = useState({});
  const [patientPreferences, setPatientPreferences] = useState({ preferredDay: [], preferredSession: '' });
  const [patientIdFromDropDown , setPatientIdFromDropdown] = useState();
  const  addAppointmentRef = useRef(null);
 
  const tabbedInterfaceRef = useRef();

  const context = useContext(GlobalContext);

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm(
      // {resolver: yupResolver(schema),}
    );

  useEffect(() => {
    const elements = document.getElementsByClassName('sketch-picker color-picker');

    if (elements && elements.length > 0) {
      const element = elements[0];

      if (element.childNodes.length > 0) {
        element.childNodes[0].style.display = 'none';
        element.childNodes[1].style.display = 'none';
        element.childNodes[2].style.display = 'none';
      }
    }
  });

  /**
    * Combines slots and appointments data into a single events array.
    * This effect runs when either appointmentsEvents or slotsEvents change.
    */
  
  useEffect(() => {
    const timer = setTimeout(() => {
      getSlotsAndAppointmentsData();
      getAllAppointments();
    }, 200); 
    return () => clearTimeout(timer);
  }, [currentDate, viewType, activeIndex]);

  // const getPatientBarData = () =>{
  //  if( window.location.href.includes("create_appointments")){
  //   getSlotsAndAppointmentsData();
  //  }
  // }

  useEffect(()=>{
    setScrollTime(currentTime);
    // sessionStorage.clear();
    // window.addEventListener('PatientBar',()=>getPatientBarData());
  },[]);

  useEffect(() => {
    if (currentDateTime) {
      const newScrollTime = new Date(currentDateTime.date.getFullYear(), currentDateTime.date.getMonth(), currentDateTime.date.getDate(), currentDateTime.time.hours, currentDateTime.time.minutes);
      setScrollTime(newScrollTime);
    } else {
      setScrollTime(currentTime);
    }
  }, [currentDateTime]);

  useEffect(() => {
    setSelectedDate(currentDate);
  }, [currentDate]);

  useEffect(() => {
    if (startTime && slotInterval) {
      const parsedStartTime = moment(startTime, "HH:mm");
      const newEndTime = parsedStartTime.add(slotInterval, 'minutes').toDate();
      setEndTime(newEndTime);
      setValue('endTime', formatTime(newEndTime));
    }
  }, [startTime, slotInterval]);
  
  useEffect(() => {
    
    window.addEventListener('PatientBar', getSlotsAndAppointmentsData);

    return () => {
      window.removeEventListener('PatientBar', getSlotsAndAppointmentsData);
    };
  });
  
  useEffect(() => { 
    if (context.patientData) {
      let patientData = context.patientData;
      setPatientPreferences({
        preferredDay: patientData.preferredDay || [],
        preferredSession: patientData.preferredSession || ''
      });
    } 
    
    if (patientIdFromDropDown){
      let patientData =  patientIdFromDropDown;
      setPatientPreferences({
        preferredDay: patientData.preferredDay || [],
        preferredSession: patientData.preferredSession || ''
      });
    }
  }, [context.patientData, patientIdFromDropDown]);
  

  useEffect(()=>{
     setDialogVisible(false);
  },[context?.clinicData])

  const convertTo24Hour = (timeStr) => {
    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes] = time.split(':');
  
    if (hours === '12') {
      hours = '00'; // Handle midnight case
    }
  
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12; // Convert PM hour to 24-hour format
    }
  
    return { hours: parseInt(hours, 10), minutes: parseInt(minutes, 10) };
  };
  
  useEffect(()=>{
    const currentDate = new Date();
    let clinicData = JSON.parse(localStorage.getItem('ClinicData')) ? JSON.parse(localStorage.getItem('ClinicData')) : '';
    if(localStorage.getItem('ClinicData')){
      const { hours, minutes } = convertTo24Hour(clinicData.startTime);
      const calendarFromTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), hours, minutes);
      setCalendarFromtime(calendarFromTime);
    }
    if(clinicData?.endTime){
      const { hours, minutes } = convertTo24Hour(clinicData.endTime);
      const calendarToTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), hours, minutes);
      setCalendarToTime(calendarToTime);
    }
    if(clinicData?.startTime && clinicData?.endTime){
      setTimesAreSet(true)
    }else{
      setTimesAreSet(false)
    }
  
  },[localStorage.getItem('ClinicData')])
  
  useEffect(() => {

    // This will set the dialog to invisible when currentAction is "updateAppointment" and patientData changes
    if (currentAction === "updateAppointment" && context?.patientData) {
      setDialogVisible(false);
    }
  }, [context?.patientData]);
  

  useEffect(() => {

    // This will set the dialog to invisible when currentAction is "updateAppointment" and patientData changes
    if (currentAction === "updateAppointment" && context?.patientData) {
      setDialogVisible(false);
    }
  }, [context?.patientData]);
   
  function formatTime(date) {
    return moment(date).format("HH:mm");
  }

  const getWeekRange = (date) => {
    const startOfWeek = moment(date).startOf('week').toDate();
    const endOfWeek = moment(date).endOf('week').toDate();
    return { startOfWeek, endOfWeek };
  };


    //Get All AppointMents Data
    const getAllAppointments = () => {
      const requestUrl = `${apiCalls.appointments}`;
      fetchMethodRequest('GET', requestUrl)
          .then(async (response) => {
              if (response && response.appointments) {
                  let appointmentsData = response.appointments; 
                  setAllAppointments(appointmentsData);
              }
          }).catch(error => {
              console.error('Error fetching all appointments:', error);
          });
     };


// Get Appointments , Schedules and dynamic chairs Data 
  const getSlotsAndAppointmentsData = () => {
    let filterCriteria = {
      sortfield: "created",
      direction: "desc",
      criteria: []
    };

    if (viewType === 'week') {
      const { startOfWeek, endOfWeek } = getWeekRange(currentDate);
      filterCriteria.criteria.push({
        key: "fromTime",
        value: moment(startOfWeek).format("YYYY-MM-DD"),
        type: "appointmentFromDateToDate"
      }, {
        key: "toTime",
        value: moment(endOfWeek).format("YYYY-MM-DD"),
        type: "appointmentFromDateToDate"
      });
    } else { // 'day' view
      const date = moment(currentDate).format(config.dbOnlyDateFormat);
      filterCriteria.criteria.push({
        key: "fromTime",
        value: date,
        type: "dateeq"
      });
    }

    const schedileFilterCriteria = JSON.parse(JSON.stringify(filterCriteria));

    if (localStorage.getItem('PatientData')) {
      let patientData = JSON.parse(localStorage.getItem('PatientData'));
      filterCriteria.criteria.push({
        key: "patientId",
        value: patientData._id,
        type: "eq"
      });
    }

    // Add clinic ID to filter criteria if clinicData is present
    if (localStorage.getItem('ClinicData')) {
      let clinicData = JSON.parse(localStorage.getItem('ClinicData'));
      setClinicData(clinicData);
      const clinicFilter = {
        key: "clinicId",
        value: clinicData._id,
        type: "eq"
      };
      filterCriteria.criteria.push(clinicFilter);
      schedileFilterCriteria.criteria.push(clinicFilter);
    }


    const encodedFilter = encodeURIComponent(JSON.stringify(filterCriteria));
    const shedulefilter = encodeURIComponent(JSON.stringify(schedileFilterCriteria));
    const appointmentsRequestUrl = `${apiCalls.appointments}?searchFrom=autoComplete&filter=${encodedFilter}&type=exportToCsv`;
    const schedulesRequestUrl = `${apiCalls.schedules}?searchFrom=autoComplete&filter=${shedulefilter}`;

    Promise.all([fetchMethodRequest('GET', appointmentsRequestUrl), fetchMethodRequest('GET', schedulesRequestUrl)])
      .then(async ([appointmentsResponse, schedulesResponse]) => {
        if (appointmentsResponse && appointmentsResponse.appointments && schedulesResponse && schedulesResponse.schedules) {
          setAppointments(appointmentsResponse.appointments);
          const appointmentEvents = [];
          const appointmentResources = appointmentsResponse.appointments.map(appointment => {
            const dateComponents = new Date(appointment.date);
            const fromTimeComponents = new Date(appointment.fromTime);
            const startHour = fromTimeComponents.getHours();
            const startMinute = fromTimeComponents.getMinutes();
            const toTimeComponents = new Date(appointment.toTime);
            const endHour = toTimeComponents.getHours();
            const endMinute = toTimeComponents.getMinutes();

            let patientGender = appointment.patientId ? appointment.patientId.gender : '';
            let patientName = appointment.patientId ? ` ${appointment.patientId.firstName} ${appointment.patientId.lastName}` : '';
            let patientNumber = appointment.patientId ? appointment.patientId.phoneNumber : '';
            let patientAge = appointment.patientId ? appointment.patientId.age : '';
            let patientAddress = appointment.patientId ? appointment.patientId.address : '';
            let patientTelephone = appointment.patientId ? appointment.patientId.phoneNumber : '';
            let patientNotes = appointment ? appointment.notes : '';
            let patientImage = Array.isArray(appointment.patientId.image) && appointment.patientId.image.length > 0
              ? appointment.patientId.image
              : [' '];
            let showPatientImage = appointment.patientId.showImage ?? false;
            let title = `${patientName} \nCell:${patientNumber}`;
            let procedureCodes = appointment.procedureCodes ? appointment.procedureCodes : '';
            let provider = appointment.provider.name ? appointment.provider.name : '';
            let confirmStatus = appointment.confirmationStatus ? appointment.confirmationStatus : '';
            // Combine date with start and end times
            const startDateTime = new Date(dateComponents);
            startDateTime.setHours(startHour, startMinute);
            const endDateTime = new Date(dateComponents);
            endDateTime.setHours(endHour, endMinute);
            const handlerResource = appointment.handler._id; // Create an event object and push to events array
            const appointmentId = appointment._id;
            const isAppointment = "True";
            appointmentEvents.push({
              _id: appointment._id,
              title: title,
              start: startDateTime,
              end: endDateTime,
              allDay: false,
              resourceId: handlerResource ? handlerResource : undefined,
              patientGender: patientGender,
              patientNumber: patientNumber,
              patientAge: patientAge,
              patientName: patientName,
              patientAddress: patientAddress,
              confirmationStatus: appointment.confirmationStatus,
              statusColor: getStatusColor(appointment.confirmationStatus),
              appointmentId: appointment._id,
              date: dateComponents,
              patientTelephone: patientTelephone,
              isAppointment: "True",
              patientImage: patientImage,
              showPatientImage: showPatientImage,
              provider: provider,
              confirmStatus: confirmStatus,
              procedureCodes: procedureCodes,
              patientNotes:patientNotes
              // reasons:appointment.reasons
            });
            return {
              resourceId: appointment.handler._id,
              resourceTitle: appointment.handler.name,
              type: 'appointment',
              hospitalId: appointment.hospitalId,
              _id: appointment.handler._id
            };
          });

          const summarizedEvents = schedulesResponse.schedules.map(schedule => {
            const dateComponents = new Date(schedule.fromTime);
            const fromTimeComponents = new Date(schedule.fromTime);
            const startHour = fromTimeComponents.getHours();
            const startMinute = fromTimeComponents.getMinutes();
            const toTimeComponents = new Date(schedule.toTime);
            const endHour = toTimeComponents.getHours();
            const endMinute = toTimeComponents.getMinutes();

            const startDateTime = new Date(dateComponents);
            startDateTime.setHours(startHour, startMinute);

            const endDateTime = new Date(dateComponents);
            endDateTime.setHours(endHour, endMinute);

            const handlerName = schedule.handlers.name;
            const handlerResource = schedule.handlers._id;
            const eventId = schedule._id;
            const date = schedule.fromTime.split('T')[0];
            const isSchedule = "True";
            return {
              handlerId: handlerResource,
              handler: schedule.handlers.name,
              date: date,
              start: formatDate.formatDate(startDateTime),
              end: formatDate.formatDate(endDateTime),
              allDay: false,
              isSchedule: isSchedule,
              resourceId: handlerResource ? handlerResource : undefined,
              title: `${schedule.handlers.name} - ${date}`,
              _id: eventId,
              resourceTitle: schedule.handlers.name,
              type: 'schedule',
              hospitalId: schedule.hospitalId,
            };
          });

          const scheduleResources = summarizedEvents.map(event => ({
            resourceId: event.resourceId,
            resourceTitle: event.resourceTitle,
            type: event.type,
            hospitalId: event.hospitalId,
            _id: event.handlerId
          }));


          const resourceHandlers = [...appointmentResources, ...scheduleResources].map(resource => ({
            resourceId: resource.resourceId,
            resourceTitle: resource.resourceTitle,
            type: resource.type,
            hospitalId: resource.hospitalId,
            _id: resource._id
          }));

          const uniqueHandlers = Array.from(new Set(resourceHandlers.map(a => a._id)))
            .map(_id => {
              return resourceHandlers.find(a => a._id === _id);
            }).sort((a, b) => {
              // Extract the first sequence of digits from the resource titles
              const numA = parseInt(a.resourceTitle.match(/\d+/), 10);
              const numB = parseInt(b.resourceTitle.match(/\d+/), 10);
              // If numeric parts are the same, use localeCompare to compare the full titles
              if (numA === numB) {
                return a.resourceTitle.localeCompare(b.resourceTitle);
              }
              // Otherwise, compare the numeric parts
              return numA - numB;
            });
          // a.resourceTitle.localeCompare(b.resourceTitle));
          setResources(uniqueHandlers);
          setHandlers(uniqueHandlers);
          setAppointmentsEvents(appointmentEvents);
          // { appointmentEvents && appointmentEvents.length>0 &&
          // setEvents(appointmentEvents);
          //  }
          setEvents(appointmentEvents);
          setBackgroundEvents(summarizedEvents);
        }
      }).catch(error => {
        console.error('Error fetching appointments and slots:', error);
      });
  };




  const colorNames = {
    '#D32F2F': 'Red',
    '#C2185B': 'Pink',
    '#7B1FA2': 'Purple',
    '#512DA8': 'Dark Purple',
    '#303F9F': 'Indigo',
    '#1976D2': 'Blue',
    '#0288D1': 'Light Blue',
    '#0097A7': 'Cyan',
    '#00796B': 'Teal',
    '#388E3C': 'Green',
    '#689F38': 'Lime',
    '#AFB42B': 'Yellow',
    '#F57C00': 'Orange',
    '#E64A19': 'Deep Orange',
    '#5D4037': 'Brown',
    '#795548': 'Dark Brown',
    '#004D40': 'Deep Teal',
    '#1B5E20': 'Dark Green',
    '#BF360C': 'Deep Red',
    '#3E2723': 'Dark Sienna',
    '#212121': 'Charcoal',
    '#000080': 'Navy Blue',
    '#8B0000': 'Dark Red',
    '#006400': 'Dark Green'
  };

  const nameToHex = Object.entries(colorNames).reduce((acc, [hex, name]) => {
    acc[name.toLowerCase()] = hex;
    return acc;
  }, {});

  const eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor = '';
    let style = {
      borderRadius: '0px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'block'
    };

    const matchingAppointment = appointments.find(appointment => appointment._id === event._id);

    if (matchingAppointment) {
      const lowercaseColor = matchingAppointment?.reasons?.color
        ? matchingAppointment.reasons.color.toLowerCase()
        : '';
      const colortoHex = nameToHex[lowercaseColor] || "#0077B6";
      backgroundColor = `${colortoHex}`;
    }

    style.backgroundColor = backgroundColor;

    return {
      style: style,
    };
  };


  /**
     * Handles updating the state when an event is changed (e.g., dragged or resized).
     * @param {Object} updatedEvent - The event data that has been updated.
   */
  const handleEventChange = (updatedEvent) => {
    setChangedEvents(updatedEvent);
  };

// Not Using for now Implemented in addAppointmentsData Component
 const confirmedToCreateAppointments = () =>{
  const  {data, start, end, startDate, patientId, treatmentType, color, provider} = appointmentWeekendInfo
  createAppointment(data, start, end, startDate, patientId, treatmentType, color, provider) ;
  }

  // Not Using for now Implemented in addAppointmentsData Component
  const createAppointment = (data, start, end, startDate, patientId, treatmentType, color, provider) => {
    const formattedDate = formatDate.formatDate(startDate);
    const fromTimePart = formatDate.formatDate(start);
    const toTimePart = formatDate.formatDate(end);


    let payload = {
      title: data.eventName,
      fromTime: fromTimePart,
      toTime: toTimePart,
      date: formattedDate,
      allDay: false,
      status: "Available",
      patientId: patientId,
      clinicId : patientId.clinicId,
      confirmationStatus:'Booked',
      treatmentType: treatmentType,
      color: (color && color.hex ? color.hex : color) || '#265985',
      provider: provider,
      handler: selectedHandler,
      hospitalId: selectedHospitalId
    };
    
    if (clinicData) {
      payload.clinicId = clinicData;
    }
    // Update the changed events state
    // handleEventChange(newEventOrUpdatedEvent);


    setDialogVisible(false);
    setIsLoading(true);
    const requestUrl = `${apiCalls.appointments}`;

    fetchMethodRequest('POST', requestUrl, payload)
      .then(response => {
        if(response.respCode){
          setShowWeekendConfirmModal(false)
          showToasterMessage(response.respMessage,'success');
        }else{
          showToasterMessage(response.errorMessage,'error');
        }        
      })
      .catch(error => {
        console.error('Error canceling appointment:', error);
       showToasterMessage(response.errorMessage,'danger');
      })
      .finally(() => {
        // getSlotsData();
        getSlotsAndAppointmentsData();
        getAllAppointments();
        setSlotModelTrue(0);
        setValue('provider', '');
        setValue('reasons','');
        setSlotInterval(0);
        tabbedInterfaceRef.current?.resetSelectedColor();
        setIsLoading(false);
      });

  };

  function checkForWeekends(date) {
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6; 
}

  /**
  * Handles the form submission for creating or updating appointments and slots.
  * @param {Object} data - Form data from the submission.
  */
  // Old Add Appointments Submit function Not using for Now Implemented in AddAppointmentsData
  const handleFormSubmit = (data) => {
    const startDate = new Date(data.eventDate);
    // const startTime = new Date(data.startTime);
    const endDate = new Date(data.eventDate);
    // const endTime = new Date(data.endTime);
    const status = data.status;
    // const start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startTime.getHours(), startTime.getMinutes());
    // const end = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endTime.getHours(), endTime.getMinutes());
    const patientId = data.patientId;
    const treatmentType = data.treatmentType;
    // const color = data.color;
    const color = data.reasons ? data.reasons.color : data.color;
    const provider = data.provider;
    const parseTime = (timeString, baseDate) => {
      const [hours, minutes] = timeString.split(':').map(Number);
      return new Date(baseDate.getFullYear(), baseDate.getMonth(), baseDate.getDate(), hours, minutes);
    };
    const getTimeFromDate = (time, baseDate) => {
      return new Date(baseDate.getFullYear(), baseDate.getMonth(), baseDate.getDate(), time.getHours(), time.getMinutes());
    };

    // Function to process each time input independently
    const processTimeInput = (timeInput, baseDate) => {
      if (typeof timeInput === 'string') {
        return parseTime(timeInput, baseDate);
      } else if (timeInput instanceof Date) {
        return getTimeFromDate(timeInput, baseDate);
      } else {
        console.error("Invalid time input format");
        return null;
      }
    };

    let start = processTimeInput(data.startTime, startDate);
    let end = processTimeInput(data.endTime, endDate);

    
    const includesWeekend = checkForWeekends(startDate);
    setAppointmentWeekendInfo({
      data:data ,
      start:start , 
      end:end , 
      startDate:startDate ,
      patientId:patientId , 
      treatmentType:treatmentType,
      color:color,
       provider:provider
    })
    
    if (currentAction === 'createAppointment'){
      if(includesWeekend){
        setShowWeekendConfirmModal(true)
      }
      else{
        createAppointment(data, start, end, startDate, patientId, treatmentType, color, provider);
      }


    } else if (currentAction === 'updateAppointment') {
      let appointmentId;
      let handler;
      let hospitalId;
      let color;
      const matchingAppointment = appointments && appointments.find(appointment => appointment._id === selectedEvent._id);
      if (matchingAppointment) {
        appointmentId = matchingAppointment._id;
        hospitalId = matchingAppointment.hospitalId;
        handler = matchingAppointment.handler;
        color = matchingAppointment.color;
      }
      const formattedDate = formatDate.formatDate(data.eventDate);

      const requestBody = {
        fromTime: start,
        toTime: end,
        _id: appointmentId,
        patientId: patientId,
        treatmentType: treatmentType,
        provider: provider,
        confirmationStatus: confirmStatus,
        hospitalId:hospitalId,
        handler:handler,
        color:color,
        date:startDate
      };
      if (clinicData) {
        requestBody.clinicId = clinicData;
      }
      updateAppointment(requestBody, appointmentId);
    }
  };

  /**
  * Resets the form fields in the dialog.
  */
  const resetDialogForm = () => {
    reset({
      eventName: "", // Reset with the current eventName state
      eventDate: null,
      startTime: null,
      endTime: null,
    });
    setSelectedEvent(null); // Reset the selected event
  };

  /**
  * Populates the form fields with event data when editing an existing event.
  * @param {Object} event - The event object to populate the form with.
 */
  const populateFormWithEventData = (event) => {
    setValue('eventName', event.title);
    setValue('eventDate', event.start);
    setValue('startTime', event.start);
    setValue('endTime', event.end);
  };

  /**
  * Handles the double click event on calendar events.
  * This function is triggered when a user double clicks on an event in the calendar. It is used for editing existing events.
  * @param {Object} event - The event object that was double clicked.
  */
  const onDoubleClickEvent = (event) => {
    setCurrentAction('updateAppointment');
    const selectedSlotId = event._id;
        const handler = handlers.find(emp => emp._id === event.resourceId);
        setSlotModelTrue(0);
    setSelectedHandler(handler);
    setSelectedHospitalId(handler.hospitalId 
      ? handler.hospitalId : null
      );
    setSelectedEventId(event._id);
    setEventDate(event.start);
    setStartTime(event.start);
    setEndTime(event.end);
    setCancelAppointmentId(selectedSlotId);
    setCurrentDateTime({ date: event.start, time: { hours: event.start.getHours(), minutes: event.start.getMinutes() } });
    const matchingAppointment = appointments.find(appointment => appointment._id === selectedSlotId);

    setIsMatchingSlotFound(matchingAppointment);

    if (matchingAppointment) {
      // setValue('patientId', matchingAppointment.patientId);
      // setValue('provider',matchingAppointment.provider);
      setDialogTitle('Update Appointment');
      setSelectedEvent({ ...event, _id: event._id });
      setSelectedEventId(event._id);
      // setDialogVisible(true);
      setEventNameLabel('Appointment Name');
      populateFormWithEventData(event);
    }
    if(dialogVisible){
      setDialogVisible(false);
    }
    setTimeout(() => {
      setDialogVisible(true);
    }, 100);
    setSelectedRowData({...event,patientId :matchingAppointment.patientId,provider:matchingAppointment.provider,reasons: matchingAppointment.reasons , color:matchingAppointment.color , procedureCodes:matchingAppointment.procedureCodes,notes:matchingAppointment.notes})

  };


  const localizer = momentLocalizer(moment)
  const DnDCalendar = withDragAndDrop(Calendar);

  /**
   * Handles the drag and drop functionality for events in the calendar.
   * Updates the event's start and end times based on the new position after dragging.
   * @param {Object} event - The event object with the updated position data.
   */
  const onEventDrop = ({ event, start, end, resourceId }) => {

    // Check if the event has a confirmStatus of "outthedoor"
    if (event.confirmStatus === "outthedoor") {
      setErrorMessage("Cannot move completed appointments.");
      setErrorDialogVisible(true);
      return;
    }

    if (start < new Date() || end < new Date()) {
      setErrorDialogVisible(true);
      setErrorMessage("Cannot move events to a past time.");
      return;
    }

    if (event.isSchedule) {
      setErrorMessage("Cannot edit schedule");
      setErrorDialogVisible(true);
      return;
    }
    const adjustedDropCalendarToTime = new Date(start);
    adjustedDropCalendarToTime.setHours(calendarToTime.getHours(), calendarToTime.getMinutes(), 0, 0);

    if (end > adjustedDropCalendarToTime) {
      setErrorMessage("Event duration exceeds allowed calendar end time.");
      setErrorDialogVisible(true);

      return;
    }

    const idx = events.indexOf(event);
    const updatedEvent = { ...event, start, end , resourceId  };
    const updatedEvents = [...events];
    updatedEvents[idx] = { ...event, start, end };
    handleEventChange(updatedEvent);
    setDraggedEvent({ event, start, end , resourceId });
    setIsDragConfirmDialogVisible(true);
    setCurrentDate(start);
    setCurrentDateTime({ date: start, time: { hours: start.getHours(), minutes: start.getMinutes() } });

  };

  /**
   * Handles the resizing of events in the calendar.
   * Updates the event's start and end times based on the new size after resizing.
   * @param {Object} event - The event object with the updated size data.
   */
  const onEventResize = ({ event, start, end }) => {

    
    // Check if the event has a confirmStatus of "outthedoor"
    if (event.confirmStatus === "outthedoor") {
      setErrorMessage("Cannot move completed appointments.");
      setErrorDialogVisible(true);
      return;
    }

    if (end < new Date()) {
      setErrorDialogVisible(true);
      setErrorMessage("Cannot resize events to a past time.");
      return;
    }

    const adjustedResizeCalendarToTime = new Date(start);
    adjustedResizeCalendarToTime.setHours(calendarToTime.getHours(), calendarToTime.getMinutes(), 0, 0);
    if (end > adjustedResizeCalendarToTime) {
      setErrorDialogVisible(true);
      setErrorMessage("Event end time cannot exceed the calendar's closing time.");
      return;
    }
    setResizeEvent({ event, start, end });
    setIsResizeConfirmDialogVisible(true);
    setCurrentDate(start);
    setCurrentDateTime({ date: start, time: { hours: start.getHours(), minutes: start.getMinutes() } });
  };

  /**
   * Renders a confirmation dialog for confirming event resize.
   * @returns {JSX.Element} The resize confirmation dialog component.
   */
  const ResizeConfirmDialog = () => (
    <Dialog
      header="Confirm Event Resize"
      visible={isResizeConfirmDialogVisible}
      draggable={false}
      onHide={() => setIsResizeConfirmDialogVisible(false)}
      footer={
        <div>
          <Button label="No" onClick={() => setIsResizeConfirmDialogVisible(false)} />
          <Button label="Yes" onClick={confirmResizeChange} />
        </div>
      }
    >
      <p>Do you want to update the event time?</p>
    </Dialog>
  );
  /**
   * Confirms the event resize change and updates the event data accordingly.
   */
  const confirmResizeChange = async () => {
    if (resizeEvent) {
      const updatedEvent = events.find(e => e._id === resizeEvent.event._id);
      if (updatedEvent) {
        const eventId = updatedEvent.appointmentId ? updatedEvent.appointmentId : updatedEvent._id;

        let requestUrl;
        let requestBody;


        if (updatedEvent.patientName) {
          let hospitalId, handler, patientId,color,confirmationStatus,provider , reasons,procedureCodes ,notes;

          appointments.forEach(appointment => {
            if (appointment._id === updatedEvent.appointmentId) {
              hospitalId = appointment?.hospitalId;
              handler = appointment?.handler;
              patientId = appointment?.patientId;
              color=appointment?.color;
              confirmationStatus=appointment?.confirmationStatus;
              provider=appointment?.provider;
              reasons = appointment?.reasons;
              procedureCodes=appointment?.procedureCodes;
              notes=appointment?.notes;
            }
          });
          requestBody = {
            fromTime: formatDate.formatDate(resizeEvent.start),
            toTime: formatDate.formatDate(resizeEvent.end),
            date: formatDate.formatDate(resizeEvent.start),
            hospitalId: hospitalId,
            handler: handler,
            patientId: patientId,
            color:color,
            confirmationStatus:confirmationStatus,
            provider:provider,
            reasons:reasons,
            _id: eventId,
            procedureCodes:procedureCodes,
            notes:notes
          };

          if (clinicData) {
            requestBody.clinicId = clinicData;
          }
          requestUrl = `${apiCalls.appointments}/${updatedEvent.appointmentId}`;
        } else {
          console.error('Event does not meet criteria for either slots or appointments update');
          return;
        }

        try {
          setIsLoading(true);
          const response = await fetchMethodRequest('PUT', requestUrl, requestBody);
          if (response && response.respMessage) {
            // Update state to reflect the changes in UI
            const updatedEvents = events.map(evt => {
              if (evt._id === resizeEvent.event._id) {
                return { ...evt, start: resizeEvent.start, end: resizeEvent.end };
              }
              return evt;
            });
            setEvents(updatedEvents);
            showToasterMessage(response.respMessage,'success');
          } else if(response && response.errorMessage){
            showToasterMessage(response.errorMessage,'danger');
          }
        } catch (error) {
          console.error('Error updating event:', error);
        } finally {
          setIsLoading(false);
          // getSlotsData();
          getSlotsAndAppointmentsData();
          getAllAppointments();
        }
      }
    }
    setIsResizeConfirmDialogVisible(false);
    setResizeEvent(null);
  };

  const createDefaultTime = () => {
    const defaultDateTime = new Date();
    defaultDateTime.setHours(12, 0, 0, 0);
    return defaultDateTime;
  };
  /**
   * Renders a confirmation dialog for confirming changes made by dragging events.
   * It prompts the user to confirm or cancel the changes made by dragging an event to a new time slot.
   * @returns {JSX.Element} The drag confirmation dialog component.
   */
  const DragConfirmDialog = () => (
    <Dialog
      header="Confirm Event Change"
      visible={isDragConfirmDialogVisible}
      draggable={false}
      onHide={() => setIsDragConfirmDialogVisible(false)}
      footer={
        <div>
          <Button label="No" onClick={() => setIsDragConfirmDialogVisible(false)} />
          <Button label="Yes" onClick={confirmDragChange} />
        </div>
      }
    >
      <p>Do you want to update the event time?</p>
    </Dialog>
  );
  /**
   * Confirms the changes made by dragging an event and updates the event data accordingly.
   * It is called when the user confirms the changes in the drag confirmation dialog.
   */
  const confirmDragChange = async () => {
    if (draggedEvent) {
      const updatedEvent = events.find(e => e._id === draggedEvent.event._id);
      if (updatedEvent) {
        const eventId = updatedEvent.appointmentId ? updatedEvent.appointmentId : updatedEvent._id;

        let requestUrl;
        let requestBody;

        if (updatedEvent.patientName) {
          // For appointments
          let selectedHandler;
          let patientId;
          let hospitalId;
          let color;
          let confirmationStatus;
          let provider;
          let reasons;
          let procedureCodes;
          let notes;
          handlers.forEach(handler => {
            if(handler._id === draggedEvent.resourceId){
              selectedHandler=handler;
            }
          });
          appointments.forEach(appointment => {
            if (appointment._id === updatedEvent.appointmentId) {
              // handler = appointment.handler;
              patientId = appointment?.patientId;
              hospitalId = appointment?.hospitalId;
              color=appointment?.color;
              confirmationStatus=appointment?.confirmationStatus;
              provider=appointment?.provider;
              reasons = appointment?.reasons;
              procedureCodes=appointment?.procedureCodes;
              notes=appointment?.notes;
            }
          });
          requestBody = {
            fromTime: formatDate.formatDate(draggedEvent.start),
            toTime: formatDate.formatDate(draggedEvent.end),
            date: formatDate.formatDate(draggedEvent.start),
            handler: selectedHandler,
            hospitalId:hospitalId,
            patientId: patientId,
            color:color,
            provider:provider,
            confirmationStatus:confirmationStatus,
            reasons:reasons,
            _id: eventId,
            procedureCodes:procedureCodes,
            notes:notes
          };

          if (clinicData) {
            requestBody.clinicId = clinicData;
          }
          requestUrl = `${apiCalls.appointments}/${updatedEvent.appointmentId}`;
        } else {
          console.error('Event does not meet criteria for either slots or appointments update');
          return;
        }

        try {
          setIsLoading(true);
          const response = await fetchMethodRequest('PUT', requestUrl, requestBody);
          if (response && response.respMessage) {
            showToasterMessage(response.respMessage,'success');

            setDialogVisible(false);
          } else if(response && response.errorMessage){
            showToasterMessage(response.errorMessage,'danger');

          }
        } catch (error) {
          console.error('Error updating event:', error);

        } finally {
          setIsLoading(false);
          getSlotsAndAppointmentsData();
          getAllAppointments();
          // getSlotsData();
        }
      }
    }
    setIsDragConfirmDialogVisible(false);
    setDraggedEvent(null);
  };
  const handleStartTimeChange = (newStartTime) => {
    setStartTime(newStartTime);
  };
  /**
   * Handles the selection of time slots in the calendar.
   * This function is triggered when a user selects a time slot. It is used for creating new events.
   * @param {Object} slotInfo - Contains information about the selected time slot.
   */
  const handleSelectSlot = (e) => {
    const {start, end, resourceId, action } = e
    // console.log('abcd'); 
    const currentDateTime = new Date();
    const interval = moment(end).diff(moment(start), 'minutes');
    setSlotInterval(interval);
    const startDay = new Date(start);
    if (selectedPasteEvent) {
      setShowConfirmationDialog(true);
      setStartTime(start);
      setPasteEventOperative(resourceId);
    }
    else {
      if (startDay < currentDateTime && action !== "click") {
        setErrorDialogVisible(true);
        setErrorMessage('Cannot create appointments before current time.');
      } else {
        setCurrentAction('createAppointment');
        if(action !=='click'){
          if(dialogVisible){
            setDialogVisible(false);
          }
          setDialogVisible(true);
        }
        const handler = handlers && handlers.find(emp => emp._id === resourceId);
        setSelectedHospitalId(handler.hospitalId);
        setSelectedHandler(handler);
        setEventDate(start);
        setStartTime(start);
        setEndTime(end);
        setCurrentDate(start);
        setCurrentDateTime({ date: start, time: { hours: start.getHours(), minutes: start.getMinutes() } });
        setDialogTitle('Add Details');
        setEventNameLabel('Appointment Name');
        setSlotModelTrue(0);
        setSelectedRowData({...e,eventDate:start,startTime:start,endTime:end})

      };
    }
  }
  const onHide = () => {
    reset({
      eventName: "",
      eventDate: null,
      startTime: null,
      endTime: null,
    });
    setDialogVisible(false);
    setValue('provider', '');
    tabbedInterfaceRef.current?.resetSelectedColor();
  };

  /**
   * Imperative handle hook to expose component methods to parent components.
   * It allows parent components to directly interact with the methods of this component.
   */
  useImperativeHandle(ref, () => ({
    getEvents: () => {
      return changedEvents;
    },
    setCloseDialogVisible: () => {
      setDialogVisible(false);
      getSlotsAndAppointmentsData();
      // getSlotsData();
      setValue('provider', '');
      setSlotModelTrue(0);
    },
  }));

  /**
   * Updates an existing appointment with new data.
   * This function is called when updating an appointment through the UI.
   * @param {Object} requestBody - The updated data for the appointment.
   * @param {string} appointmentId - The ID of the appointment to update.
   */
  // Not Using For Now Implemented in AddAppointmentsData Component  
  const updateAppointment = async (requestBody, appointmentId) => {
    const requestUrl = `${apiCalls.appointments}/${appointmentId}`;
    try {
      setIsLoading(true);
      const response = await fetchMethodRequest('PUT', requestUrl, requestBody);
      if (response && response.respMessage) {
        setDialogVisible(false);
        setValue('provider', '');
        showToasterMessage(response.respMessage,'success');
      } else {
        showToasterMessage(response.errorMessage,'danger');
      }
    } catch (error) {
      console.error('Error updating appointment:', error);
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while updating the appointment.', life: 3000 });
    } finally {
      setIsLoading(false);
      getSlotsAndAppointmentsData();
      getAllAppointments();
      // getSlotsData();
      setSlotModelTrue(0);
    }
  };

  const confirmDeletion = (event) => {
    setEventToDelete(event);
    setShowDeleteConfirmation(true);
  };

  const DeleteConfirmationDialog = () => (
    <Dialog
      header="Confirm Deletion"
      visible={showDeleteConfirmation}
      draggable={false}
      onHide={() => setShowDeleteConfirmation(false)}
      footer={
        <div>
          <Button label="No" onClick={() => setShowDeleteConfirmation(false)} />
          <Button label="Yes" onClick={() => handleDeleteConfirmed(eventToDelete)} />
        </div>
      }
    >
      <p>Are you sure you want to delete this event?</p>
    </Dialog>
  );


  const handleDeleteConfirmed = (event) => {
    if (event) {
      let requestUrl;
      if (event.appointmentId) {
        requestUrl = `${apiCalls.appointments}/${event.appointmentId}`;
      } else {
        // URL for deleting a slot
        requestUrl = `${apiCalls.schedules}/${event._id}`;
      }

      // API call to delete the slot or appointment
      fetchMethodRequest('DELETE', requestUrl)
        .then(response => {
          if (response.respMessage="Appointments delete successfully.") {
            // Remove the event from the state
            setEvents(events.filter(event => event._id !== event._id));
            showToasterMessage(response.respMessage,'success');
          } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to delete event.', life: 3000 });
          }
        })
        .catch(error => {
          console.error('Error deleting event:', error);
          showToasterMessage(response.errorMessage,'danger');
        })
        .finally(() => {
          setSelectedPasteEvent(null)
          setShowDeleteConfirmation(false);
          setEventToDelete(null);
          getSlotsAndAppointmentsData();
          getAllAppointments();
          // getSlotsData();
        });
    }
  };

  const slotStyleGetter = (date) => {
    const startOfDay = moment(date).startOf('day');
    const minutesSinceStartOfDay = moment(date).diff(startOfDay, 'minutes');
    const isTenMinuteInterval = minutesSinceStartOfDay % 15 === 0;
    const isSixtyMinuteInterval = minutesSinceStartOfDay % 60 === 50;

    if (isSixtyMinuteInterval) {
      return {
        style: {
          borderBottom: '1px solid #008080',
        },
      };
    } else if (isTenMinuteInterval) {
      return {
        style: {
          borderBottom: '1px solid #ccc',
        },
      };
    } else {
      return {};
    }
  };

  const statusOptions = [
    { label: 'Unconfirmed', value: 'unconfirmed', color: '#FF6347' }, // Tomato
    { label: 'Confirmed', value: 'confirmed', color: '#FF4500' }, //Orange Red
    { label: 'Arrived', value: 'arrived', color: '#32CD32' }, // Lime Green
    { label: 'Ready', value: 'ready', color: '#FFD700' }, // Gold
    { label: 'In Room', value: 'inroom', color: '#1E90FF' }, // Dodger Blue
    { label: 'Checkout', value: 'checkout', color: '#FF69B4' }, // Hot Pink
    { label: 'Left Msg', value: 'leftmsg', color: '#8A2BE2' }, // Blue Violet
    { label: 'Bad Num', value: 'badnum', color: '#A52A2A' }, // Brown
    { label: 'E-mailed', value: 'emailed', color: '#20B2AA' }, // Light Sea Green
    { label: 'Texted', value: 'texted', color: '#FFA500' }, // Orange
    { label: 'E-Confirm Sent', value: 'econfirmsent', color: '#778899' }, // Light Slate Gray
    { label: 'E-Confirm Call', value: 'econfirmcall', color: '#DB7093' }, // Pale Violet Red
    { label: 'E-Confirm Fault', value: 'econfirmfault', color: '#2E8B57' }, // Sea Green
    { label: 'Web Sched', value: 'websched', color: '#6A5ACD' }, // Slate Blue
    { label: 'Out The Door', value: 'outthedoor', color: '#4B0082' } // Indigo
  ];
  const handleStatusChange = (e) => {
    setConfirmStatus(e.value);
    const updatedEvents = events.map(event => {
      if (event._id === selectedEventId) {
        return {
          ...event,
          confirmationStatus: e.value,
          statusColor: getStatusColor(e.value)
        };
      }
      return event;
    });

    setEvents(updatedEvents);
  };

  const getStatusColor = (status) => {
    const statusObj = statusOptions.find(option => option.value === status);
    return statusObj ? statusObj.color : '#000000'; // Default color if status not found
  };
  const EventComponent = ({ event }) => {
    const [tooltipShow, setTooltipShow] = useState(false);
    const [tooltipTarget, setTooltipTarget] = useState(null);
    const target = useRef(null);

    const onSelectEventAction = (event) => {
      setSelectedPasteEvent(event);
    };

    const startDate = new Date(event.start);
    const endDate = new Date(event.end);
    const duration = (endDate - startDate) / (1000 * 60);
    const eventStyle = {
      display: duration <= 30 ? 'flex' : '',
      justifyContent: duration <= 30 ? 'space-between' : '',
      width: '100%',
      paddingTop: duration <= 30 ? '10px' : '25px',
      position: 'relative',
      borderRadius: '4px'
    };
    const renderThumbnail = () => {
      const containerStyle = {
        width: '100px',
        marginLeft: '0',
        marginBottom: '10px'
      };
      if (event.patientName) {
        if (event.patientImage && // Check if patientImage exists
          Array.isArray(event.patientImage) && // Check if it's an array
          event.patientImage.length > 0 && // Ensure array is not empty
          event.patientImage[0].trim() !== "" && // Ensure the first element is not an empty string
          event.showPatientImage // Check if showPatientImage is true
        ) {
          const imageUrl = `${config.imgUrl}/patients/${event.patientImage[0]}`;
          return (
            <div style={containerStyle}>
              <img src={imageUrl} alt="Patient" style={{ width: '100px', height: '100px', borderRadius: '4px', border: '2px solid white' }} />
            </div>
          );
        } else {
          return (
            <div style={containerStyle}>
              <div style={{
                width: '100px',
                height: '100px',
                backgroundColor: '#000',
                color: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '0.8em',
                borderRadius: '4px',
                border: '2px solid white'
              }}>
                Patient picture unavailable
              </div>
            </div>
          );
        }
      }
    };

  
    /**
     * 
     * @param {Object} appointmentData 
     */
    const setPatientOnTopBar = (event) => {
      const appointmentData = appointments.find(appointment => appointment._id === event._id)
  
    }
    
    const onMouseEnter = (e) => {
      setTooltipTarget(e.target);
      setTooltipShow(true);
    };

    const onMouseLeave = () => {
      setTooltipShow(false);
    };
    const renderEventContent = () => {
      let patientDetails = null;
      if (event.patientName) {
        patientDetails = `${event.patientName || ''}`;
        if (event.patientAge && event.patientGender) {
          patientDetails += ` (Age: ${event.patientAge}, ${event.patientGender})`;
        }
      }

      const content = (
        <div>
          {event.patientName && !event.thumbnailUrl && <div>{renderThumbnail()}</div>}
          <div style={{ textAlign: 'left' }}>
            {patientDetails && <div>{patientDetails}</div>}
            {event.provider && <div>Provider: {event.provider}</div>}
            {event.patientNumber && <div>Phone: {event.patientNumber}</div>}
            {event.confirmStatus && <div>Status: {event.confirmStatus}</div>}
            {event.patientNotes && <div>Notes: {event.patientNotes}</div>}
          </div>
        </div>
      );
    
      if (event.handler && event.status) {
        return (
          <div className="event-content" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {/* for the Slot we should Display the Empty White space */}
            {/* <p className='handler'>{event.handler}</p>
            <p className='status'>Status: {event.status}</p>
            {tooltipShow && (
              <Overlay target={tooltipTarget} show={tooltipShow} placement="auto">
                <Tooltip id={`tooltip-${event._id}`} className='events-tooltip'>
                  {content}
                </Tooltip>
              </Overlay>
            )} */}
          </div>
        );
      } else if (event.patientName) {
        
        return (
          <div className="event-content" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <p className='patient-name'>{event.patientName}</p>
            {duration <= 30 ? (
              <>
                {event.patientNumber ? <p className='patient-number'>Phone: {event.patientNumber}</p> : <div>Phone: -</div>}
                {tooltipShow && (
                  <Overlay target={tooltipTarget} show={tooltipShow} placement="auto">
                    <Tooltip id={`tooltip-${event._id}`} className='events-tooltip'>
                      {content}
                    </Tooltip>
                  </Overlay>
                )}
              </>
            ) : (
              <>
                {event.provider ? <p className='patient-name'>Provider: {event.provider}</p> : <div>Provider: -</div>}
                {event.patientNumber ? <p className='patient-number'>Phone: {event.patientNumber}</p> : <div>Phone: -</div>}
                {tooltipShow && (
                  <Overlay target={tooltipTarget} show={tooltipShow} placement="auto">
                    <Tooltip id={`tooltip-${event._id}`} className='events-tooltip'>
                      {content}
                    </Tooltip>
                  </Overlay>
                )}
              </>
            )}
          </div>
        );
      }
    
      return (
        <div className="event-content" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          No details available
          {tooltipShow && (
            <Overlay target={tooltipTarget} show={tooltipShow} placement="auto">
              <Tooltip id={`tooltip-${event._id}`} className='events-tooltip'>
                {content}
              </Tooltip>
            </Overlay>
          )}
        </div>
      );
    };
    return (
      <div className="custom-event" ref={target} style={eventStyle} onClick={e=>setPatientOnTopBar(event)}>
        <div className="event-icons">
          {event.patientName && <span  onClick={e=>e.stopPropagation()}>
            <FontAwesomeIcon icon={faCopy} data-toggle="tool-tip" title="Copy" className='icon-circle' onClick={() => onSelectEventAction(event)} />
            <FontAwesomeIcon icon={faCircle} data-toggle="tool-tip" title="Confirm Status" className="icon-circle" style={{ color: event.statusColor }} />
            {event.confirmStatus && event.confirmStatus !== "checkout" && <FontAwesomeIcon icon={faPen} data-toggle="tool-tip" title="Edit" className='icon-circle' onClick={() => onDoubleClickEvent(event)} />}
            <FontAwesomeIcon icon={faTrash} data-toggle="tooltip" title="Delete" className='icon-circle' onClick={() => confirmDeletion(event)} />
            </span>
          }
        </div>
        {renderEventContent()}
      </div>
    );
  };


  const formats = {
    timeGutterFormat: (date, culture, localizer) => {
      const hours = localizer.format(date, 'hh A', culture); // Includes AM/PM
      const minutes = localizer.format(date, 'mm', culture);

      if (minutes === '00') {
        return `${hours}`;
      }
      return `${minutes}`;
    },
  };

  const isThisWeekend =  (date) =>{ 
    const dateString = date
    const dayOfWeek = dateString.getDay();
    if (dayOfWeek === 6 || dayOfWeek === 0) {
        return true;
    } else {
        return false;
    } 
  }

  const getWeekendConfirmation = (selectedDate,type)=>{
    if(type === "copy"){
      onPasteCopiedEvent(selectedDate);
    }else if(type === "cut"){
      onPasteEvent(selectedDate);
    }
  }

  const onPasteWeekendInfo = (selectedDate , type)=>{
    const isWeekend =  isThisWeekend(selectedDate.selectedDate)
    setCopiedType(type)
    setCopiedDate(selectedDate);
    if(isWeekend){
      setShowConfirmationDialog(false);
      setIsWeekendConfirm(true);
    }else{
      getWeekendConfirmation(selectedDate,type);
    }
}

  const ActionConfirmationDialog = (selectedDate) => (
    <Dialog header={`Modify Appointment`} visible={showConfirmationDialog} onHide={() =>PasteCopiedCancelFunction()}
      footer={
        <>
          <Button label="Cut & Paste" onClick={() => onPasteWeekendInfo(selectedDate , 'cut')} />
          <Button label="Copy & Paste" onClick={() => onPasteWeekendInfo(selectedDate , 'copy')} />
          <Button label="Cancel" onClick={() => PasteCopiedCancelFunction()} />
        </>
      }>
      <p>Are you sure you want to continue with ?</p>
    </Dialog>
  );
  const onPasteCopiedEvent = async (selectedDate) => {
    if (selectedPasteEvent) {
      const eventId = selectedPasteEvent.appointmentId ? selectedPasteEvent.appointmentId : null;

      const eventDuration = selectedPasteEvent.end.getTime() - selectedPasteEvent.start.getTime();

      let requestUrl;
      let requestBody;

      const fromTime = new Date(new Date(selectedDate.selectedDate.setHours(startTime.getHours(), startTime.getMinutes())));
      const toTime = new Date(fromTime.getTime() + eventDuration);
      const adjustedCutCalendarToTime = new Date(fromTime);
      adjustedCutCalendarToTime.setHours(calendarToTime.getHours(), calendarToTime.getMinutes(), 0, 0);
      
      if (fromTime < new Date() || toTime < new Date()) {
        setShowConfirmationDialog(false);
        setErrorDialogVisible(true);
        setErrorMessage("Cannot paste event to a past time.");
        return;
      }

      if (toTime > adjustedCutCalendarToTime) {
        setErrorDialogVisible(true);
        setErrorMessage("Pasted event end time cannot exceed the calendar's closing time.");
        return;
      }
      
      if (selectedPasteEvent.patientName) {
        let selectedHandler;
        let patientId;
        let hospitalId;
        let color;
        let confirmationStatus;
        let provider;
        let reasons;
        let procedureCodes;

        handlers.forEach(handler => {
          if(handler._id === pasteEventOperative){
            selectedHandler=handler;
          }
        });
        allAppointments.forEach(appointment => {
          if (appointment._id === selectedPasteEvent.appointmentId) {
            // handler = appointment.handler;
            patientId = appointment.patientId;
            hospitalId = appointment.hospitalId;
            color=appointment.color;
            confirmationStatus=appointment.confirmationStatus;
            provider=appointment.provider;
            reasons = appointment.reasons;
            procedureCodes=appointment.procedureCodes;
          }
        });
        requestBody = {
          fromTime: formatDate.formatDate(new Date(selectedDate.selectedDate.setHours(startTime.getHours(), startTime.getMinutes()))),
          // toTime: formatDate.formatDate(new Date(selectedDate.selectedDate.setHours(cutEvent.end.getHours(), cutEvent.end.getMinutes()))),
          toTime:formatDate.formatDate(toTime),
          date: formatDate.formatDate(new Date(selectedDate.selectedDate)),
          handler: selectedHandler,
          hospitalId: hospitalId,
          patientId: patientId,
          color: color,
          confirmationStatus:'Booked',
          provider:provider,
          reasons:reasons,
          procedureCodes:procedureCodes
        };

        if (clinicData) {
          requestBody.clinicId = clinicData;
        }
        requestUrl = `${apiCalls.appointments}`;
      } else {
        console.error('Event does not meet criteria for update');
        return;
      }
      try {
        setIsLoading(true);
        const response = await fetchMethodRequest('POST', requestUrl, requestBody);
        if (response && response.respMessage) {
          showToasterMessage(response.respMessage, 'success');
          setIsWeekendConfirm(false);

        } else if(response && response.errorMessage){
          showToasterMessage(response.errorMessage, 'error');
        }
      } catch (error) {
        console.error('Error updating event:', error);
      } finally {
        setIsLoading(false);
      }

      setSelectedPasteEvent(null);
      setShowConfirmationDialog(false);
      getSlotsAndAppointmentsData();
      getAllAppointments();
      setPasteEventOperative(null);
      setCurrentDateTime({ date: selectedDate.selectedDate, time: { hours: startTime.getHours(), minutes: selectedPasteEvent.start.getMinutes() } });
    }
  };

  const PasteCopiedCancelFunction = () => {
    setShowConfirmationDialog(false);
    setCurrentDateTime({ date:selectedPasteEvent.start, time: { hours: selectedPasteEvent.start.getHours(), minutes: selectedPasteEvent.start.getMinutes() } });
    setSelectedPasteEvent(null);
    getSlotsAndAppointmentsData();
  }
  const onPasteEvent = async (selectedDate) => {
    if (selectedPasteEvent) {
      const eventId = selectedPasteEvent.appointmentId ? selectedPasteEvent.appointmentId : null;

      const eventDuration = selectedPasteEvent.end.getTime() - selectedPasteEvent.start.getTime();

      let requestUrl;
      let requestBody;

      const fromTime = new Date(new Date(selectedDate.selectedDate.setHours(startTime.getHours(), startTime.getMinutes())));
      const toTime = new Date(fromTime.getTime() + eventDuration);

      const adjustedCutCalendarToTime = new Date(fromTime);
      adjustedCutCalendarToTime.setHours(calendarToTime.getHours(), calendarToTime.getMinutes(), 0, 0);
      
      if (fromTime < new Date() || toTime < new Date()) {
        setShowConfirmationDialog(false);
        setErrorDialogVisible(true);
        setErrorMessage("Cannot paste event to a past time.");
        return;
      }

      if (toTime > adjustedCutCalendarToTime) {
        setErrorDialogVisible(true);
        setErrorMessage("Pasted event end time cannot exceed the calendar's closing time.");
        return;
      }
      if (selectedPasteEvent.patientName) {
        let selectedHandler;
        let patientId;
        let hospitalId;
        let color;
        let confirmationStatus;
        let provider;
        let reasons;
        let procedureCodes;
        handlers.forEach(handler => {
          if(handler._id === pasteEventOperative){
            selectedHandler=handler;
          }
        });
        allAppointments.forEach(appointment => {
          if (appointment._id === selectedPasteEvent.appointmentId) {
            // handler = appointment.handler;
            patientId = appointment.patientId;
            hospitalId = appointment.hospitalId;
            color=appointment.color;
            confirmationStatus=appointment.confirmationStatus;
            provider=appointment.provider;
            reasons = appointment.reasons;
            procedureCodes=appointment.procedureCodes;
          }
        });
        requestBody = {
          fromTime: formatDate.formatDate(new Date(selectedDate.selectedDate.setHours(startTime.getHours(), startTime.getMinutes()))),
          // toTime: formatDate.formatDate(new Date(selectedDate.selectedDate.setHours(cutEvent.end.getHours(), cutEvent.end.getMinutes()))),
          toTime:formatDate.formatDate(toTime),
          date: formatDate.formatDate(new Date(selectedDate.selectedDate)),
          handler: selectedHandler,
          hospitalId: hospitalId,
          patientId: patientId,
          color: color,
          confirmationStatus: confirmationStatus,
          provider:provider,
          _id: eventId,
          reasons:reasons,
          procedureCodes:procedureCodes,
        };

        if (clinicData) {
          requestBody.clinicId = clinicData;
        }
        requestUrl = `${apiCalls.appointments}/${eventId}`;
      } else {
        console.error('Event does not meet criteria for update');
        return;
      }

      try {
        setIsLoading(true);
        const response = await fetchMethodRequest('PUT', requestUrl, requestBody);
        if (response && response.respMessage) {
          showToasterMessage(response.respMessage, 'success');
          setIsWeekendConfirm(false);

        } else if(response && response.errorMessage) {
          console.error('Update failed:', response);
          showToasterMessage(response.errorMessage, 'danger');
        }
      } catch (error) {
        console.error('Error updating event:', error);
      } finally {
        setIsLoading(false);
      }

      setSelectedPasteEvent(null);
      setShowConfirmationDialog(false);
      getSlotsAndAppointmentsData();
      getAllAppointments();
      setPasteEventOperative(null);
      setCurrentDateTime({ date: selectedDate.selectedDate, time: { hours: startTime.getHours(), minutes: selectedPasteEvent.start.getMinutes() } });
    }
  };
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [currentView, setCurrentView] = useState('day');

  const isToday = () => {
    const today = new Date();
    return today.getFullYear() === date.getFullYear() &&
      today.getMonth() === date.getMonth() &&
      today.getDate() === date.getDate() ;
  };
  const hightLight =(type)=>{
     // const highlightToday = isToday() && currentView === 'day' && !sessionStorage.calenderViewType
     const highlightToday = isToday() && (viewType === 'today');
     const isDayView = currentView === 'day' && currentView !== 'week' && !highlightToday; 
     const isWeekView = currentView === 'week' && currentView !== 'day' && !highlightToday;
     let isToDate =  isToday() ;
     if(type === 'today' && isToDate
    //  && currentView === 'day'
      && viewType === type
     ){
      return true;
     }else if (type === 'day' && currentView === type && viewType === type ){
      return true; 
     }else if(type === 'week' && currentView === type && viewType === type){
      return true; 
     }
     return false;
 
  }

  // Function to compare only the date parts
  const areDatesEqual = (newDate, currentDate) => {
    return newDate.getFullYear() === currentDate.getFullYear() &&
         newDate.getMonth() === currentDate.getMonth() &&
         newDate.getDate() === currentDate.getDate();
    }

  const CustomToolbar = ({ label, date, onNavigate, onView }) => {
    

    const navigate = action => {
      onNavigate(action);
    };

    const navigateMonth = (direction) => {
      const newDate = new Date(date);
      newDate.setMonth(date.getMonth() + direction);
      setDate(newDate);
      setValue('eventDate', newDate);
      onNavigate(Navigate.DATE, newDate);
      if (areDatesEqual(newDate, new Date())) {
        setViewType('today');
      } else {
        setViewType('day');
      }
    };

    const navigateWeek = (direction) => {
      const newDate = new Date(date);
      newDate.setDate(date.getDate() + (direction * 7));
      setDate(newDate);
      setValue('eventDate', newDate);
      onNavigate(Navigate.DATE, newDate);
      if (areDatesEqual(newDate, new Date())) {
        setViewType('today');
      } else {
        setViewType('day');
      }
    };
    const view = view => {
      onView(view);
      setCurrentView(view);
      setViewType(view);
      // sessionStorage.setItem('calenderViewType', view)
    };

    const handleDateSelect = (newDate) => {
      setValue('eventDate', newDate);
      setDate(newDate);
      onNavigate(Navigate.DATE, newDate);
      onView(Views.DAY);
      setIsCalendarVisible(false);
      setSelectedDate(newDate);
    };

    // Function to toggle the calendar visibility
    const openCalendar = () => {
      setIsCalendarVisible(!isCalendarVisible);
    };
    

    const formattedLabel = currentView === 'week'
      ? moment(date).format('MMMM YYYY')
      : `${moment(date).format('ddd').charAt(0).toUpperCase() + moment(date).format('ddd').slice(1)} - ${moment(date).format('DD MMM YYYY').toUpperCase()}`;


    const handleTodayClick = () => {
      const today = new Date();
      setDate(today);
      setSelectedDate(today);
      onNavigate(Navigate.TODAY);
      onView(Views.DAY);
      setCurrentView('day');
      setViewType('today')
      // setViewType('day');

    };
    return (

      <div className="rbc-toolbar">
        <Toast ref={toast} />
        <span className="rbc-btn-group">
          {selectedPasteEvent &&
            <>
              <button type="button">Copied</button>
              <button type="button" onClick={() => { PasteCopiedCancelFunction() }}>Cancel</button>
            </>
          }
        </span>
        <div className="date-label-container" >
          <button type="button" onClick={() => navigateMonth(-1)}><strong>M</strong></button>
          <button type="button" onClick={() => navigateWeek(-1)}><strong>W</strong></button>
          <button type="button"
            // onClick={() => navigate(Navigate.PREVIOUS)}
            onClick={() => {
              let newDate = new Date(date);
              newDate.setDate(newDate.getDate() - 1);
              setDate(newDate);
              setSelectedDate(newDate);
              onNavigate(Navigate.PREVIOUS);
              setValue('eventDate', newDate);
              if (areDatesEqual(newDate, new Date())) {
                setViewType('today');
              } else {
                setViewType('day');
              }
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <span className="rbc-toolbar-label" onClick={openCalendar}>
            {formattedLabel}
            <FontAwesomeIcon icon={faCaretDown} className='down-arrow' />
          </span>

          {isCalendarVisible &&
            <div className="calendar-popup">
              <PrimeCalendar
                className='toolbar-calendar'
                value={date}
                onSelect={(e) => handleDateSelect(e.value)}
                inline
              />
            </div>
          }
          <button type="button"
            // onClick={() => navigate(Navigate.NEXT)}
            onClick={() => {
              let newDate = new Date(date);
              newDate.setDate(newDate.getDate() + 1);
              setDate(newDate);
              setSelectedDate(newDate);
              onNavigate(Navigate.NEXT);
              setValue('eventDate', newDate);
              if (areDatesEqual(newDate, new Date())) {
                setViewType('today');
              } else {
                setViewType('day');
              }
              // setViewType('day');
            }}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
          <button type="button" onClick={() => navigateWeek(1)}><strong>W</strong></button>
          <button type="button" onClick={() => navigateMonth(1)}><strong>M</strong></button>
          <button type="button" onClick={() => navigateMonth(3)}><strong>3</strong></button>
          <button type="button" onClick={() => navigateMonth(4)}><strong>4</strong></button>
          <button type="button" onClick={() => navigateMonth(6)}><strong>6</strong></button>
        </div>
        <span className="rbc-btn-group">
          <button type="button" onClick={handleTodayClick} className={clsx({ 'rbc-active': hightLight('today') })}>Today</button>
          <button type="button" onClick={() => view(Views.DAY)} className={clsx({ 'rbc-active': hightLight('day') })}>Day</button>
          <button type="button" onClick={() => view(Views.WEEK)} className={clsx({ 'rbc-active': hightLight('week') })}>Week</button>
        </span>
      </div>
    );
  };

  const getColumnClass = () => {
    if (dialogVisible) {
      if (currentAction === 'createAppointment'|| currentAction === 'updateAppointment') {
        return "col-8";
      }
    }
    return "col-12";
  };

  const getSidebarClass = () => {
    if (dialogVisible) {
      if (currentAction === 'createAppointment'  || currentAction === 'updateAppointment' ) {
        return "col-4";
      }
      // else if (currentAction === 'updateAppointment' || currentAction === 'createSlot') {
      //   return "col-3";
      // }
    }
    return "d-none";
  };
  const handleSlotModal = (value) => {
    setSlotModelTrue(value);
  };

  /**
   * Handles navigation actions in the calendar (e.g., moving to next/previous month).
   * @param {Date} newDate - The new date to navigate to.
   */
  const handleNavigate = (newDate) => {
    setIsLoading(true);
    setCurrentDate(newDate);
    setChangedDate(newDate);

    // Simulate fetching data with a delay (replace this with your actual data fetching logic)
    setTimeout(() => {
      // Example of completing navigation and data fetch
      setIsLoading(false); // Reset loading state after data is fetched
    }, 1000); // Simulate delay
  };

  const handleTodayClick = () => {
    const today = new Date();
    setCurrentDate(today);
  };

  const onTabChange = (e) => {
    if (e.index !== 2 && e.index !== 3) {
        setActiveIndex(e.index);
    }
};
  
   //get Weekend confirmation Modal
   const getWeekendConfirmModal = () =>{
    return  <WeekendConfirmModal 
      isOpenWeekendModal = {isWeekendConfirm}
      label="Are you sure you want to create schedules for weekends?"
      closeWeekendModal={()=>setIsWeekendConfirm(false)}
      confirm = {()=>getWeekendConfirmation(copiedDate,copiedType)}
      />
    }


   
  const customHeader = () => (
    <div className='Tab-Panel-Icon' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', fontSize: '12.5px' }}>
        {patientPreferences.preferredSession && patientPreferences.preferredDay.length > 0 && (
          <>
            <div>
              Preferred Session: <span style={{ color: 'black' }}>{patientPreferences.preferredSession}</span>
            </div>
            <div>
              Preferred Days: <span style={{ color: 'black' }}>{patientPreferences.preferredDay.join(', ')}</span>
            </div>
          </>

        )}
      </div>
    </div>
  );

  const customHeader1 = ()=>{
    return <div className='schedule_redirection'>
    No available schedules for Operatories.
    <Link to='/create_schedules'>Click here for Create Schedules</Link>
  </div>
  }

  const selectPatientFromDropdown = (value) => {
    setPatientIdFromDropdown(value);
  };

  /**
   * Returns the appropriate label for the submit button based on the current action.
   * @returns {string} The label for the submit button.
   */
  return (
    <div className='appointment-calendar'>
      <Toast ref={toast} />
      {isLoading && (
        <div className="spinner-container">
          <Loader loader={isLoading}/>
        </div>
      )}
      {/* {resources.length === 0 && (
              <div style={{ color: 'red', textAlign: 'right' }}>
                No available schedules for Operatories.
                <Link to='/create_schedules'>Click here for Create Schedules</Link>
              </div>
            )} */}
      <TabView onTabChange={(e) => onTabChange(e)} activeIndex={activeIndex} className='Tab-view'>
        <TabPanel header={<FontAwesomeIcon icon={faCalendarCheck} className='Tab-Panel-Icon' />}>
          <div style={{ paddingTop: "10px" }}>
            
            <div className='row'>
              <div className={getColumnClass()}>
                <DnDCalendar
                  defaultDate={currentDate}
                  scrollToTime={scrollTime}
                  resources={resources}
                  defaultView="day"
                  events={events}
                  backgroundEvents={backgroundEvents}
                  dayLayoutAlgorithm="no-overlap"
                  localizer={localizer}
                  onEventDrop={onEventDrop}
                  tooltipAccessor={false}
                  onEventResize={onEventResize}
                  {...(timesAreSet ? { min: calendarFromTime, max: calendarToTime } : {})}
                  // onDoubleClickEvent={onDoubleClickEvent}
                  resizable={true}
                  onNavigate={handleNavigate}
                  selectable={true}
                  onSelectSlot={handleSelectSlot}
                  resourceTitleAccessor="resourceTitle"
                  resourceIdAccessor="resourceId"
                  eventPropGetter={eventStyleGetter}
                  step={15}
                  timeslots={1}
                  formats={formats}
                  slotPropGetter={slotStyleGetter}
                  components={{
                    event: EventComponent,
                    toolbar: CustomToolbar

                  }}
                  views={['day', 'week']}
                />

                {/* <DndCalender
                currentDate={currentDate}
                scrollToTime={scrollTime}
                resources={resources}
                defaultView="day"
                events={events}
                backgroundEvents={backgroundEvents}
                dayLayoutAlgorithm="no-overlap"
                localizer={localizer}
                onEventDrop={onEventDrop}
                tooltipAccessor={false}
                onEventResize={onEventResize}
                resizable={true}
                selectable={true}
                onSelectSlot={handleSelectSlot}
                timesAreSet={timesAreSet}
                calendarFromTime={calendarFromTime}
                calendarToTime={calendarToTime}
                onNavigate={handleNavigate}
                resourceTitleAccessor="resourceTitle"
                resourceIdAccessor="resourceId"
                step={15}
                timeslots={1}
                formats={formats}
                slotPropGetter={slotStyleGetter}
                eventPropGetter={eventStyleGetter}
                // components={{
                //   event: EventComponent,
                //   toolbar: CustomToolbar
                // }}
                EventComponent = {EventComponent}
                CustomToolbar={CustomToolbar}
                views={['day', 'week']}

                /> */}

              </div>
              {dialogVisible && <div className={getSidebarClass()}>
                <div className='calendar-sidebar'>
                  <div className="calendar-form-container">
                    <div class='d-flex schedule-dialog-header'>
                      <div>
                        <h1 class="calendar-form-header">{dialogTitle}</h1>
                      </div>
                      <button class="close-icon" onClick={onHide}>
                        X
                      </button>
                    </div>
                    {/* <form onSubmit={handleSubmit(handleFormSubmit)} className="calendar-form"> */}
                    <div className="calendar-form">
                      {(currentAction === 'createAppointment' || currentAction === 'updateAppointment') && (
                        // <TabbedInterface
                        //   eventDate={eventDate}
                        //   startTime={startTime}
                        //   endTime={endTime}
                        //   control={control}
                        //   onHandleSlotModal={handleSlotModal}
                        //   currentAction={currentAction}
                        //   setSelectedEvent={setSelectedEvent}
                        //   slots={slots}
                        //   slotModelTrue={slotModelTrue}
                        //   setSelectedEventId={setSelectedEventId}
                        //   handleStatusChange={handleStatusChange}
                        //   slotInterval={slotInterval}
                        //   handleStartTimeChange={handleStartTimeChange}
                        //   ref={tabbedInterfaceRef}
                        //   getValues={getValues}
                        // />

                        <AddAppointmentsData
                          eventDate={eventDate}
                          startTime={startTime}
                          endTime={endTime}
                          formType={currentAction}
                          setValue={setValue}
                          selectedHandler={selectedHandler}
                          selectedHospitalId={selectedHospitalId}
                          getSlotsAndAppointmentsData={getSlotsAndAppointmentsData}
                          onHide={onHide}
                          ref={addAppointmentRef}
                          setDialogVisible={setDialogVisible}
                          selectedRowData={selectedRowData}
                          handleStatusChange={handleStatusChange}
                          appointments={appointments}
                          confirmStatus={confirmStatus}
                          setStartTime={setStartTime}
                          setSlotModelTrue={setSlotModelTrue}
                          slotModelTrue={slotModelTrue}
                          getAllAppointments={getAllAppointments}
                          eventsData={{ events, setEvents }}
                          selectedEventId={selectedEventId}
                          selectPatientFromDropdown={selectPatientFromDropdown}
                        />
                      )}
                      {/* {currentAction === 'updateAppointment' && (
                       <UpdateAppointmentModal
                        eventDate={eventDate}
                        startTime={startTime}
                        endTime={endTime}
                        control={control}
                        handleStatusChange={handleStatusChange}
                       />
                       )} */}
                    </div>
                    {/* {currentAction !== 'updateAppointment' && (
                          <div class="schedule-footer">
                            <Button label="Submit" type="submit" className='appointment-submit-btn' />
                          </div>
                        )} */}

                    {/* {currentAction === 'updateAppointment' && (
                        <div class="schedule-footer">
                          <Button onClick={(e) => updateAppointment(e)} className='appointment-cancel-btn'>Update</Button>
                        </div>
                      )} */}
                    {/* </form> */}
                  </div>
                </div>
              </div>}

            </div>
          </div>

          {showWeekendConfirmModal && (
            <Dialog
              header="Create Appointment"
              visible={showWeekendConfirmModal}
              onHide={() => setShowWeekendConfirmModal(false)}
              footer={
                <>
                  <Button label="Yes" onClick={() => { confirmedToCreateAppointments() }} />
                  <Button label="No" onClick={() => { setShowWeekendConfirmModal(false); }} />
                </>
              }>
              <p>Are you sure you want to create Appointment for weekends?</p>
            </Dialog>
          )}

          {isWeekendConfirm ? getWeekendConfirmModal() : null}
          {isResizeConfirmDialogVisible && ResizeConfirmDialog()}
          {isDragConfirmDialogVisible && DragConfirmDialog()}
          <DeleteConfirmationDialog />
          <ActionConfirmationDialog selectedDate={selectedDate} />
          <Dialog header="Attention" visible={errorDialogVisible} style={{ width: '50vw' }} onHide={() => setErrorDialogVisible(false)} className="custom-dialog" draggable={false} >
            <div>{errorMessage}</div>
            <Button label="OK" onClick={() => setErrorDialogVisible(false)} />
          </Dialog>
        </TabPanel>

        <TabPanel header={<FontAwesomeIcon icon={faListOl} className='Tab-Panel-Icon' />}>
          <AppointmentCards />
        </TabPanel>
        {(
          dialogVisible &&
          (
            (context.patientData && ((context.patientData.preferredDay && context.patientData.preferredDay.length > 0) || (context.patientData.preferredSession && context.patientData.preferredSession.trim() !== ""))) ||
            (patientIdFromDropDown && ((patientIdFromDropDown.preferredDay && patientIdFromDropDown.preferredDay.length > 0) || (patientIdFromDropDown.preferredSession && patientIdFromDropDown.preferredSession.trim() !== "")))
          )
        ) && (
            <TabPanel header={customHeader()}>
            </TabPanel>
          )}
        {/* {resources.length === 0 && <TabPanel header={customHeader1()} disabled>
        </TabPanel>} */}
        {resources.length === 0 && (
        <TabPanel
          header={
            <div style={{display:'flex',flexDirection:'row',color:'red'}}>
              <div>
              No available schedules for Operatories.
              </div>
              <div>
              <Link to="/create_schedules" style={{ marginLeft: '5px' }}>Click here to Create Schedules</Link>
              </div>
            </div>
          }
          className='appointments-error-msg'
        >
          {/* Content of the tab panel */}
        </TabPanel>
        )}
      </TabView>
    </div>
  )
});

export default DoBigCalendar;





