import React, { useEffect, useState ,useRef} from "react";
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSave, faCheck, faAsterisk, faTimes } from '@fortawesome/free-solid-svg-icons';
import FormModal from "../../../Form/FormModal";
import apiCalls from "../../../../config/apiCalls";
import fetchMethodRequest from "../../../../config/service";
import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";

function FamilyRelations(props) {

    const [isOpenFormModal, setIsOpenFormModal] = useState(false);
    const [stateOptions, setStateOptions] = useState();
    let { i, item, Controller, control, getValues, setValue, errors, selectedData, setCustomFieldValidation, loginRole } = props;
    const [familyRelationShip, setFamilyRelationShip] = useState([]);
    const [formType, setFormType] = useState(props.formType)
    const [tableRowData, setTableRowData] = useState();
    let formModalRef = useRef(null);

    const familyRelationOptions = [
        { label: "Brother", value: "Brother" },
        { label: "Father", value: "Father" },
        { label: "Mother", value: "Mother" },
        { label: "Child", value: "Child" },
        { label: "CareGiver", value: "CareGiver" },
        { label: "FosterChild", value: "FosterChild" },
        { label: "Friend", value: "Friend" },
        { label: "Grandchild", value: "Grandchild" },
        { label: "Grandfather", value: "Grandfather" },
        { label: "Grandmother", value: "Grandmother" },
        { label: "Grandparent", value: "Grandparent" },
        { label: "Guardian", value: "Guardian" },
        { label: "LifePartner", value: "LifePartner" },
        { label: "Other", value: "Other" },
        { label: "Parent", value: "Parent" },
        { label: "Self", value: "Self" },
        { label: "Sibling", value: "Sibling" },
        { label: "Sister", value: "Sister" },
        { label: "Sitter", value: "Sitter" },
        { label: "Spouse", value: "Spouse" },
        { label: "Stepchild", value: "Stepchild" },
        { label: "Stepfather", value: "Stepfather" },
        { label: "Stepmother", value: "Stepmother" },
    ]

    const tableFields = [
        { name: 'firstName', header: 'Name', type: 'text', editable: false, field: 'firstName', style: { width: '8%', borderLeft: '1px solid #dedede', textAlign: "left" }, className: '', placeholder: 'Name' },
        { name: 'address', header: 'Address', type: 'number', editable: true, field: 'address', style: { width: '12%', textAlign: "left" }, className: '' },
        { name: 'relationEmail', header: 'E-Mail', type: 'email', editable: true, field: 'relationEmail', style: { width: '8%', textAlign: "left" }, className: '', placeholder: '' },
        { name: 'Action', header: 'Actions', type: 'Action', editable: false, field: 'name', style: { width: '2%', borderRight: '1px solid #dedede' , textAlign: "center"}, className: '' },
    ]

    const getFormFields = () => {
        return [
            {
                name: "firstName",
                type: "text",
                placeholder: "First Name",
                label: "First Name",
                derivedValue: "firstName=undefined",
                capitalizeTableText: false,
                id: "firstName",
                required: true,
                show: true,
                mobile: true,
                displayInSettings: true,
                customWidthClass: 'col-sm-6',
                style: {
                    color: "#0e4768",
                    cursor: "pointer",
                    textTransform: "capitalize",
                },
            },
            {
                name: "lastName",
                type: "text",
                placeholder: "Last Name",
                label: "Last Name",
                derivedValue: "lastName=undefined",
                capitalizeTableText: false,
                id: "lastName",
                required: true,
                show: true,
                mobile: true,
                displayInSettings: true,
                customWidthClass: 'col-sm-6',
                style: {
                    color: "#0e4768",
                    cursor: "pointer",
                    textTransform: "capitalize",
                },
            },
            // {
            //     name: "relationDateofBirth",
            //     type: "date",
            //     placeholder: "DOB",
            //     label: "DOB",
            //     required: false,
            //     dateFormat: "MM-DD-YYYY",
            //     displayOptionsInActions: false,
            //     showOrHideFields: [],
            //     customWidthClass: 'col-sm-6 mt-1',
            //     mobile: true,
            //     displayInSettings: true,
            // },
            {
                name: "relationship",
                type: "dropDown",
                placeholder: "Relationship",
                label: "relation Ship",
                id: "relationship",
                required: true,
                displayOptionsInActions: false,
                globalSearchField: "true",
                controllerId: null,
                show: true,
                options: familyRelationOptions,
                filter: true,
                showOrHideFields: [],
                customWidthClass: 'col-sm-6 mt-1',
                mobile: true,
                displayInSettings: true,
            },

            {
                name: "address",
                type: "text",
                placeholder: "Address",
                label: "Address",
                required: true,
                displayOptionsInActions: false,
                globalSearchField: "true",
                customWidthClass: 'col-sm-6',
                show: true,
                mobile: true,
                displayInSettings: true,

            },
            {
                name: "address2",
                type: "text",
                placeholder: "Address2",
                label: "Address2",
                required: false,
                displayOptionsInActions: false,
                showOrHideFields: [],
                customWidthClass: 'col-sm-6',
                show: true,
                mobile: true,
                displayInSettings: true,
            },

            {
                name: "city",
                type: "text",
                placeholder: "City",
                label: "City",
                id: "city",
                required: false,
                globalSearchField: "true",
                show: true,
                showOrHideFields: [],
                customWidthClass: 'col-sm-6',
                mobile: true,
                displayInSettings: true,
            },
            {
                name: "state",
                type: "dropDown",
                placeholder: "State",
                label: "State",
                id: "state",
                required: false,
                displayOptionsInActions: false,
                globalSearchField: "true",
                controllerId: null,
                show: true,
                options: stateOptions,
                itemTemplate: true,
                filter: true,
                showOrHideFields: [],
                customWidthClass: 'col-sm-3 mt-1',
                mobile: true,
                displayInSettings: true,
            },
            {
                name: "zipCode",
                type: "number",
                placeholder: "ZIP Code",
                label: "zip Code",
                id: "zipCode",
                required: false,
                customWidthClass: 'col-sm-3 mt-0',
                displayOptionsInActions: false,
                show: true,
                mobile: true,
                displayInSettings: true,
            },
            {
                name: "relationEmail",
                type: "email",
                placeholder: "Email",
                label: "Email",
                id: "relationEmail",
                required: false,
                customWidthClass: 'col-sm-6',
                displayOptionsInActions: false,
                show: true,
                mobile: true,
                displayInSettings: true,
            },

        ];
    };

    useEffect(() => {
        setStateData();
    }, []);

    useEffect(() => {
        setEditOptions();
    }, [selectedData]);

    const setEditOptions = () => {
        if (formType === "edit") {
            let arr = selectedData?.familyMember && selectedData?.familyMember.length > 0 ? selectedData.familyMember : [];
            if (arr && arr.length > 0) {
                setValue('familyMember', arr);
                setFamilyRelationShip(arr.map((obj, i) => { obj._id = i+''; return obj }));
            }

        }
    }

    const setStateData = async () => {
        let arr = [];
        if (localStorage.StatesOptions) {
            arr = await JSON.parse(localStorage.StatesOptions);
        } else {
            let data = await fetchMethodRequest('GET', apiCalls.Settings).then(res => {
                if (res.settings[0]?.states?.length) {
                    arr = res.settings[0]?.states.map(option => { return { label: option.split('-')[1].trim(), value: option, displayLabel: option } })
                }
            })
        }
        setStateOptions(arr);
    }


   

    // Add Family
    const addNewFamily = (e) => {
        setTableRowData({});
        e.preventDefault();
        e.stopPropagation();
        setFormType('add');
        setCustomFieldValidation({ save: false, });
        setIsOpenFormModal(true);
    }

    // const getFamilyRelationShip = (values) => {
    //     values._id = familyRelationShip.length+''
    //     if (values ) {
    //         const arr = [ ...familyRelationShip, values] 
    //         setValue('familyMember', arr)
    //         setFamilyRelationShip(arr);
    //         closeFormModal();
    //         setTableRowData({})
    //     }
    //     return true;
    // }

   
    const getFamilyRelationShip = async (values) => {
        let arrData = await familyRelationShip;
        values._id = formType === "add" ? familyRelationShip.length + '':tableRowData._id;
        if (formType === "add") {
            arrData = [ ...arrData, values] 
            setCustomFieldValidation({ save: false, });

        } else if(formType === "edit") {
            arrData = await arrData.map((obj) => {
                if (obj._id === values._id) {
                    return values
                }
                return obj;
            })
        }
        await setFamilyRelationShip(arrData);
        await setValue('familyMember', arrData);
        await closeFormModal();
        await setTableRowData({});
        return true;
    }

    const closeFormModal = (formType, response) => {
        setIsOpenFormModal(false);
        setCustomFieldValidation({ save: true });
    }

    // Get Actions
    const onClickActions = (e, rowData, type) => {
        if (type === "delete") {
            let newArr = [...familyRelationShip];
            newArr.splice(JSON.parse(rowData._id), 1);
            const updatedArr = newArr.map((obj, i) => ({ ...obj, _id: i+'' }));
            setFamilyRelationShip(updatedArr);
            setValue('familyMember', updatedArr);
        }else{
            let formType = type == 'edit' ?  'edit' : 'add'
            setFormType(formType)
            setTableRowData(rowData);
            setCustomFieldValidation({ save: false, });
            setTimeout(() => {
                setIsOpenFormModal(true);
            }, 100);
        }

    }

    //get Table Body
    const columnBody = (rowData, col) => {
        
        if (col.field === "firstName") {
            return <div style={{textTransform: "capitalize"}}>{`${rowData.firstName} ${rowData.lastName}`}</div>;
        }
        if (col.field === 'address') {
            const {address, address2, city, state, zipCode} = rowData;
            const formattedAddress = `${address} ${address2}`;
            return <div style={{textTransform: "capitalize"}}>{formattedAddress}</div>;
        }
       
        if (col.name == 'Action' && familyRelationShip.length > 0) {
            return <div className="">
                <FontAwesomeIcon
                    className='genderIconAlignment'
                    color='white'
                    icon='trash-alt'
                    data-toggle="tool-tip"
                    title="Delete"
                    style={{ color: '#bf1725', width: '13', marginLeft: 10, cursor: 'pointer' }}
                    onClick={(e) => onClickActions(e, rowData, 'delete')}
                />
                <FontAwesomeIcon
                    className='genderIconAlignment'
                    color='white'
                    icon='edit'
                    data-toggle="tool-tip" title="Edit"
                    style={{ color: '#024a88', width: '16', marginLeft: 10, cursor: 'pointer' }}
                onClick={(e) => onClickActions(e, rowData, 'edit')}
                />

            </div>
        }
        
        return rowData[col.name]
    }

    return (
        <>
            <div className='mt-2'>
                <b>Add Family Member</b>
                <Button onClick={(event) => {
                            event.preventDefault(); // Prevent default form submission
                            addNewFamily(event); 
                        }}rounded className="drugsfield_add_icon mt-2" type="button">
                    <FontAwesomeIcon
                        icon={faPlus}
                        size='lg'
                        data-toggle="tool-tip"
                        title={"Add"}
                        type="button"
                    /></Button>
            </div>
            <div>
                {
                    <DataTable value={familyRelationShip} className="mt-3">
                        {tableFields.map((col) => {
                            return <Column field={col.field} header={col.header} body={(rowData) => columnBody(rowData, col)} style={col.style} />
                        })}
                    </DataTable>}
            </div>

            {isOpenFormModal &&
                <FormModal
                    onRef={(ref) => formModalRef = ref}
                    openFormModal={isOpenFormModal}
                    allUsersData={ familyRelationShip ? familyRelationShip :[]}
                    tableRowData={tableRowData ? tableRowData : {}}
                    selectedId={tableRowData?._id ? tableRowData._id : null}
                    totalRecords={1}
                    first={1}
                    rows={1}
                    closeFormModal={closeFormModal}
                    type={'Family'}
                    tablefieldsToShow={[]}
                    originalTableFields={[]}
                    getFamilyRelationShip={getFamilyRelationShip}
                    formType={tableRowData?._id ? 'edit' :'add'}
                    // formFields={Pharmacies().getFormFields}
                    formFields={getFormFields}
                    getDataFromServer={() => { }}
                    apiUrl={apiCalls.patients}
                    filterCriteria={{}}
                    menuList={[]}
                    screen={'relationShip'}
                    // routeTo={apiCalls.prescriptions}
                    displayViewOfForm={'modal'}
                    actionsTypes={'add'}
                    entityType={'users'}
                />
            }
        </>
    )
}
export default FamilyRelations
